/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSiteMetadata } from './hooks/useSiteMetadata';
import { useLocation } from '@reach/router';

function SEO({ title, description, image, slug, locale, pathname, children }) {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage,
    siteUrl,
    author
  } = useSiteMetadata();

  const location = useLocation();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || defaultImage,
    url: `${siteUrl}${pathname || ``}`,
    author
  };

  return (
    <>
      <html lang={locale} />
      <title>{seo.title}</title>
      
     

      {/* GTM Script */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-K4887CZB');
          `
        }}
      />

      <script type="application/javascript" src="https://sdp.eu.usercentrics.eu/latest/uc-block.bundle.js"></script>

      {/* Meta tags */}
      {pathname && pathname.includes('/preview') && (
        <meta name='robots' content='noindex' />
      )}
      <meta name='description' content={seo.description} />
      <meta property='og:title' content={seo.title} />
      <meta property='og:description' content={seo.description} />
      <meta property='og:image' content={seo.image} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='1200' />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={seo.url} />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:creator' content={seo.author} />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:image' content={seo.image} />
      <meta name='twitter:site' content='@marbetEvents' />
      <meta name='google-site-verification' content='' />

      {/* Canonical and alternate language links */}
      <link
        rel='canonical'
        href={`${location.origin || siteUrl}${slug ? `/${slug}` : ''}` || ''}
        hrefLang='x-default'
      />
      <link
        rel='alternate'
        href={`${location.origin || siteUrl}/de${slug ? `/${slug}` : ''}` || ''}
        hrefLang='de'
      />
      <link
        rel='alternate'
        href={`${location.origin || siteUrl}/en${slug ? `/${slug}` : ''}` || ''}
        hrefLang='en'
      />
      <link
        rel='alternate'
        href={`${location.origin || siteUrl}/es${slug ? `/${slug}` : ''}` || ''}
        hrefLang='es'
      />
      {children}
    </>
  );
}

SEO.defaultProps = {
  description: ``,
  image: '',
  locale: 'en'
};

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.any,
  locale: PropTypes.string,
  noindex: PropTypes.bool
};

export default SEO;
